<template>
 <v-list :color="bids" @click.native="chatInit" class="rounded-lg" one-line >
        <v-list-item class="my-0" link>
          <v-list-item-avatar>
            <v-icon class="grey">
              {{ chatStatus ? 'mdi-lock-outline' : 'mdi-chat'}}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="ml-4">
              <v-clamp :max-lines="2">{{ lastMessage ? lastMessage : 'Start Conversation'}} </v-clamp>
          </v-list-item-content>

          <v-badge inline v-if="messages > 0" :content="messages"> </v-badge>
          <v-list-item-action class="ml-n1">
            <v-icon large>
              mdi-chevron-right
            </v-icon>
          </v-list-item-action>
        </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import generalMxn from "@/mixins/general_mixin";
import colorMxn from "@/mixins/colorMxn";
import VClamp from "vue-clamp";
import connection from '@/socket/utils';

export default {
  name: "chatStarter",
  props: ['room', 'room_id'],
  mixins: [colorMxn, generalMxn],
  components: {
    VClamp
  },
  data () {
    return {
      lastMessage: '',
      messages: 0,
      chatStatus: false,
      roomId: '',
      roomObj: this.room,
      chatSocket: null,
    }
  },
  computed: {
    ...mapGetters(['getChatStatus'])
  },
  mounted() {
    if (this.room) {
      this.lastMessage = this.room.last_message;
      this.messages = this.room.messages;
      this.chatStatus = this.room.is_locked;
      this.roomId = this.room.id;
    }
    if (this.room_id) {
      this.roomId = this.room_id;
      this.getRoom();
    }
    connection.connect();
    this.chatSocket = connection.subscribe(`chat:${this.roomId}`, this.handleMessageAdd);
  },
  beforeDestroy() {
    this.chatSocket.close();
  },
  methods: {
    ...mapMutations(["setChatStatus"]),
    ...mapActions(['performGetActions']),
    chatInit() {
      const data = {
        status: this.getChatStatus,
        room: this.roomObj,
      };
    this.setChatStatus(data);
    },
    async getRoom() {
      const fullPayload = {
        endpoint: `/room/show/${this.roomId}`,
      }
      const response = await this.performGetActions(fullPayload);
      this.lastMessage = response[0].last_message
      this.messages = response[0].messages;
      this.chatStatus = response[0].is_locked;
      this.roomObj = response[0];
    },
    handleMessageAdd(message) {
      this.lastMessage = message.text;
      this.messages = this.messages + 1;
    }
  }
};
</script>
